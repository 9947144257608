@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
.h-modal-icon {
  font-size: 66px; }

.inmodal .color-line {
  border-radius: 4px 4px 0 0; }

.modal-content {
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2); }

.modal-backdrop.in {
  opacity: 0.4; }

.modal-backdrop {
  position: fixed;
  bottom: 0; }

.modal-dialog {
  margin-top: 80px; }

.modal-title {
  font-size: 30px;
  font-weight: 300; }

.modal-header {
  padding: 40px 30px;
  background: #f7f9fa; }

.modal-body {
  padding: 20px 30px; }

.modal-header small {
  color: var(--color4-lighten20, #6a6c6f); }

.modal-footer {
  background: #f7f9fa; }

.hmodal-info .color-line {
  background: var(--color2, #3498db); }

.hmodal-warning .color-line {
  background: #ffb606; }

.hmodal-success .color-line {
  background: var(--color1, #62cb31); }

.hmodal-danger .color-line {
  background: #c0392b; }

.hmodal-info h4.modal-title {
  color: var(--color2, #3498db); }

.hmodal-warning h4.modal-title {
  color: #ffb606; }

.hmodal-success h4.modal-title {
  color: var(--color1, #62cb31); }

.hmodal-danger h4.modal-title {
  color: #c0392b; }

.modal-max-width {
  max-width: 1000px; }

.modal-max-width-height {
  max-width: 800px;
  max-height: 500px;
  background-color: #3498db !important; }

.modal-max-width-700 {
  max-width: 700px;
  background-color: #3498db !important; }

/* Progress bar */
.progress {
  border-radius: 2px;
  margin-bottom: 10px; }

.progress-bar {
  background-color: var(--color5, #f1f3f6);
  text-align: right;
  padding-right: 10px;
  color: var(--color4, #6a6c6f); }

.progress-small, .progress-small .progress-bar {
  height: 10px; }

.progress-bar-success {
  border-right: 4px solid var(--color1-darken5, #62cb31); }

.progress-bar-info {
  border-right: 4px solid var(--color2-darken5, #3498db); }

.progress-bar-warning {
  border-right: 4px solid #eca600; }

.progress-bar-danger {
  border-right: 4px solid var(--color3-darken5, #e74c3c); }

.full .progress-bar-success {
  background-color: var(--color1, #62cb31);
  border-right: 4px solid var(--color1-darken5, #62cb31); }

.full .progress-bar-info {
  background-color: var(--color2, #3498db);
  border-right: 4px solid var(--color2-darken5, #3498db); }

.full .progress-bar-warning {
  background-color: #ffb606;
  border-right: 4px solid #eca600; }

.full .progress-bar-danger {
  background-color: var(--color3, #e74c3c);
  border-right: 4px solid var(--color3-darken5, #e74c3c); }

.full .progress-bar {
  color: #ffffff; }

.badge.badge-success {
  background-color: var(--color1, #62cb31); }

.badge.badge-info {
  background-color: var(--color2, #3498db); }

.badge.badge-danger {
  background-color: var(--color3, #e74c3c); }

.badge.badge-warning {
  background-color: #ffb606; }

.badge.badge-primary {
  background-color: #34495e; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #34495e;
  border-color: #34495e; }

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: var(--color3, #e74c3c);
  border-color: var(--color3, #e74c3c); }

.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: var(--color2, #3498db);
  border-color: var(--color2, #3498db); }

.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #ffb606;
  border-color: #ffb606; }

.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: var(--color1, #62cb31);
  border-color: var(--color1, #62cb31); }

.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }

.radio-primary input[type="radio"] + label::after {
  background-color: #34495e; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #34495e; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #34495e; }

.radio-danger input[type="radio"] + label::after {
  background-color: var(--color3, #e74c3c); }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: var(--color3, #e74c3c); }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: var(--color3, #e74c3c); }

.radio-info input[type="radio"] + label::after {
  background-color: var(--color2, #3498db); }

.radio-info input[type="radio"]:checked + label::before {
  border-color: var(--color2, #3498db); }

.radio-info input[type="radio"]:checked + label::after {
  background-color: var(--color2, #3498db); }

.radio-warning input[type="radio"] + label::after {
  background-color: #ffb606; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #ffb606; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #ffb606; }

.radio-success input[type="radio"] + label::after {
  background-color: var(--color1, #62cb31); }

.radio-success input[type="radio"]:checked + label::before {
  border-color: var(--color1, #62cb31); }

.radio-success input[type="radio"]:checked + label::after {
  background-color: var(--color1, #62cb31); }

.checkbox input:hover,
.radio input:hover {
  cursor: pointer; }

.has-warning .form-control,
.has-error .form-control,
.has-success .form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

.has-error .form-control {
  border-color: var(--color3-darken10, #e74c3c) !important; }

.has-warning .form-control {
  border-color: #d29400 !important; }

.has-success .form-control {
  border-color: var(--color1-darken10, #62cb31) !important; }

.has-error .help-block, .has-error .control-label, .has-error .radio, .has-error .checkbox, .has-error .radio-inline, .has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
  color: var(--color3-darken10, #e74c3c); }

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #d29400; }

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: var(--color1-darken10, #62cb31); }

.form-control::-webkit-input-placeholder {
  color: #cccbcb; }

/* WebKit, Blink, Edge */
.form-control:-moz-placeholder {
  color: #cccbcb; }

/* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder {
  color: #cccbcb; }

/* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder {
  color: #cccbcb; }

/* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder {
  color: #cccbcb; }

/* Microsoft Edge */
.field-required {
  transition: 0.3s ease;
  border-left: 6px solid red !important; }

.field-required-drop {
  transition: 0.3s ease;
  border-left: 6px solid red !important;
  border-radius: 7px 3px 3px 7px !important;
  font-size: 1rem !important; }

.field-required-select {
  transition: 0.3s ease;
  border-left: 6px solid red !important;
  border-radius: .25rem !important;
  font-size: 1rem !important;
  padding: 0rem 0rem !important; }

.field-valid {
  transition: 0.3s ease;
  border-left: 6px solid #1ea034 !important; }

.field-valid-drop {
  transition: 0.3s ease;
  border-left: 6px solid #1ea034 !important;
  border-radius: 7px 3px 3px 7px !important;
  font-size: 1rem !important; }

.field-valid-select {
  transition: 0.3s ease;
  border-left: 6px solid #1ea034 !important;
  border-radius: .25rem !important;
  font-size: 1rem !important; }

div.field-required {
  border-left: 6px solid; }

.input-icon {
  position: absolute;
  left: 15px;
  right: 0px;
  font-size: 16px;
  color: #8f969c;
  /* Vertically center the icon in the input */
  top: calc(50% - 0.5em); }

.input-with-icon {
  padding-left: 35px; }

.input-with-icon-wrapper {
  position: relative; }

.field-select-fixo {
  min-width: 65px !important; }

.spinner {
  margin: 20px auto;
  width: 60px;
  height: 50px;
  text-align: center;
  font-size: 15px; }

.spinner > div {
  background-color: var(--color1, #62cb31);
  height: 100%;
  width: 8px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out; }

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s; }

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4); }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1); } }

.splash {
  position: fixed;
  z-index: 2000;
  background: white;
  color: gray;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none; }

.splash-title {
  text-align: center;
  max-width: 500px;
  margin: 15% auto;
  padding: 20px; }

.splash-title h1 {
  font-size: 26px; }

.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 98%;
  margin: 0 auto; }

.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both; }

.v-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em; }

.v-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: var(--color5-darken3, #f1f3f6); }

.vertical-timeline-content .btn {
  float: right; }

.vertical-timeline-block {
  position: relative;
  margin: 2em 0; }

.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both; }

.vertical-timeline-block:first-child {
  margin-top: 0; }

.vertical-timeline-block:last-child {
  margin-bottom: 0; }

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #e4e5e7;
  text-align: center;
  background: #fff; }

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px; }

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  border: 1px solid #e4e5e7; }

.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both; }

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px; }

.vertical-timeline-content p {
  margin: 1em 0 0 0;
  line-height: 1.6; }

.vertical-timeline-content .vertical-date {
  font-weight: 500;
  text-align: right;
  font-size: 13px; }

.vertical-date small {
  color: var(--color1, #62cb31);
  font-weight: 400; }

.vertical-timeline-content:after, .vertical-timeline-content:before {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.vertical-timeline-content:after {
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
  margin-top: -10px; }

.vertical-timeline-content:before {
  border-color: transparent;
  border-right-color: #e4e5e7;
  border-width: 11px;
  margin-top: -11px; }

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px; }
  .vertical-timeline-content p {
    font-size: 13px; } }

.dropdown-menu.hdropdown {
  padding: 0;
  z-index: 1060; }

.dropdown-menu.hdropdown .title {
  padding: 12px 12px;
  text-align: center;
  border-bottom: 1px solid #e4e5e7; }

.dropdown-menu.hdropdown li {
  padding: 6px 12px;
  text-align: left;
  background: var(--color5-lighten3, #f1f3f6);
  border-bottom: 1px solid #e4e5e7; }

.dropdown-menu.filedropdown li {
  padding: 6px 6px;
  text-align: left;
  background: var(--color5-lighten3, #f1f3f6);
  border-bottom: 1px solid #e4e5e7; }

.dropdown-menu.filedropdown li i {
  margin-right: 10px; }

.dropdown-menu.hdropdown.notification li {
  padding: 12px 12px; }

.dropdown-menu.hdropdown.notification li .label {
  margin-right: 10px; }

.dropdown-menu.hdropdown li > a:hover, .dropdown-menu.filedropdown li > a:hover {
  background: inherit; }

.dropdown-menu.hdropdown li:hover, .dropdown-menu.filedropdown li:hover {
  background: var(--color5, #f1f3f6); }

.dropdown-menu.hdropdown li.summary {
  padding: 6px 12px;
  text-align: center;
  background: #fff; }

.navbar-nav > li > a:hover, .navbar-nav > li > a:focus, .navbar-nav .open > a, .navbar-nav .open > a:hover, .navbar-nav .open > a:focus {
  color: inherit;
  background: var(--color5-lighten2, #f1f3f6);
  border-bottom: 1px solid #e4e5e7; }

.color-demo .header-line {
  height: 118px; }

/* Mixin */
.color-demo .header-line:after {
  display: block;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  height: 10px;
  width: 100%; }

.header-line.extreme-color {
  width: 20%; }

.header-line {
  height: 6px;
  width: 10%;
  float: left;
  margin: 0;
  position: relative; }

.header-line div {
  position: absolute;
  top: 70px;
  left: 15px;
  font-weight: 600;
  color: #fff; }

.header-line div span {
  font-weight: 400;
  display: block;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6); }

/* Buttons */
.btn {
  border-radius: 3px; }

.btn-login {
  font-weight: 600;
  font-size: 14px;
  padding: 8px 0px; }

.float-e-margins .btn {
  margin-bottom: 5px; }

.btn-primary.btn-outline {
  color: #34495e; }

.btn-primary2.btn-outline {
  color: #9b59b6; }

.btn-success.btn-outline {
  color: var(--color1, #62cb31); }

.btn-info.btn-outline {
  color: var(--color2, #3498db); }

.btn-warning.btn-outline {
  color: #ffb606; }

.btn-warning2.btn-outline {
  color: #e67e22; }

.btn-danger.btn-outline {
  color: var(--color3, #e74c3c); }

.btn-danger2.btn-outline {
  color: #c0392b; }

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: #fff; }

.btn-primary {
  background-color: #34495e;
  border-color: #34495e;
  color: #FFFFFF !important;
  margin: 0 2px; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-color: #3f5872;
  border-color: #3f5872;
  color: #FFFFFF !important; }

.btn-primary:active, .btn-primary.active, .open .dropdown-toggle.btn-primary {
  background-image: none; }

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary.active[disabled], fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #293a4a;
  border-color: #293a4a; }

.btn-primary2 {
  background-color: #9b59b6;
  border-color: #9b59b6;
  color: #FFFFFF !important; }

.btn-primary2:hover, .btn-primary2:focus, .btn-primary2:active, .btn-primary2.active, .open .dropdown-toggle.btn-primary2 {
  background-color: #a86ebf;
  border-color: #a86ebf;
  color: #FFFFFF !important; }

.btn-primary2:active, .btn-primary2.active, .open .dropdown-toggle.btn-primary2 {
  background-image: none; }

.btn-primary2.disabled, .btn-primary2.disabled:hover, .btn-primary2.disabled:focus, .btn-primary2.disabled:active, .btn-primary2.disabled.active, .btn-primary2[disabled], .btn-primary2[disabled]:hover, .btn-primary2[disabled]:focus, .btn-primary2[disabled]:active, .btn-primary2.active[disabled], fieldset[disabled] .btn-primary2, fieldset[disabled] .btn-primary2:hover, fieldset[disabled] .btn-primary2:focus, fieldset[disabled] .btn-primary2:active, fieldset[disabled] .btn-primary2.active {
  background-color: #8c49a7;
  border-color: #8c49a7; }

.btn-alternative {
  background-color: #005baa;
  border-color: #005baa;
  color: #FFFFFF !important; }

.btn-alternative:hover, .btn-alternative:focus, .btn-alternative:active, .btn-alternative.active, .open .dropdown-toggle.btn-alternative {
  background-color: #006bc9;
  border-color: #006bc9;
  color: #FFFFFF !important; }

.btn-alternative:active, .btn-alternative.active, .open .dropdown-toggle.btn-alternative {
  background-image: none; }

.btn-alternative.disabled, .btn-alternative.disabled:hover, .btn-alternative.disabled:focus, .btn-alternative.disabled:active, .btn-alternative.disabled.active, .btn-alternative[disabled], .btn-alternative[disabled]:hover, .btn-alternative[disabled]:focus, .btn-alternative[disabled]:active, .btn-alternative.active[disabled], fieldset[disabled] .btn-alternative, fieldset[disabled] .btn-alternative:hover, fieldset[disabled] .btn-alternative:focus, fieldset[disabled] .btn-alternative:active, fieldset[disabled] .btn-alternative.active {
  background-color: #004b8b;
  border-color: #004b8b; }

.btn-success {
  background-color: var(--color1, #62cb31);
  border-color: var(--color1, #62cb31);
  color: #FFFFFF !important; }

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  background-color: var(--color1-lighten6, #62cb31);
  border-color: var(--color1-lighten6, #62cb31);
  color: #FFFFFF !important; }

.btn-success:active, .btn-success.active, .open .dropdown-toggle.btn-success {
  background-image: none; }

.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success.active[disabled], fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: var(--color1-darken6, #62cb31);
  border-color: var(--color1-darken6, #62cb31); }

.btn-info {
  background-color: var(--color2, #3498db);
  border-color: var(--color2, #3498db);
  color: #FFFFFF !important;
  margin: 0 2px; }

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
  background-color: var(--color2-lighten6, #3498db);
  border-color: var(--color2-lighten6, #3498db);
  color: #FFFFFF !important; }

.btn-info:active, .btn-info.active, .open .dropdown-toggle.btn-info {
  background-image: none; }

.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info.active[disabled], fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: var(--color2-darken6, #3498db);
  border-color: var(--color2-darken6, #3498db); }

.btn-default {
  background-color: #fff;
  border-color: #e4e5e7;
  color: var(--color4, #6a6c6f);
  margin: 0 2px; }

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #d9dbdd;
  color: var(--color4-darken4, #6a6c6f); }

.btn-default:active, .btn-default.active, .open .dropdown-toggle.btn-default {
  background-image: none; }

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default.active[disabled], fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: #e4ebee;
  border-color: #e4ebee; }

.btn-warning {
  background-color: #ffb606;
  border-color: #ffb606;
  color: #FFFFFF; }

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background-color: #ffbf25;
  border-color: #ffbf25;
  color: #FFFFFF; }

.btn-warning:active, .btn-warning.active, .open .dropdown-toggle.btn-warning {
  background-image: none; }

.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning.active[disabled], fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #e6a300;
  border-color: #e6a300; }

.btn-warning2 {
  background-color: #e67e22;
  border-color: #e67e22;
  color: #FFFFFF; }

.btn-warning2:hover, .btn-warning2:focus, .btn-warning2:active, .btn-warning2.active, .open .dropdown-toggle.btn-warning2 {
  background-color: #e98e3d;
  border-color: #e98e3d;
  color: #FFFFFF; }

.btn-warning2:active, .btn-warning2.active, .open .dropdown-toggle.btn-warning2 {
  background-image: none; }

.btn-warning2.disabled, .btn-warning2.disabled:hover, .btn-warning2.disabled:focus, .btn-warning2.disabled:active, .btn-warning2.disabled.active, .btn-warning2[disabled], .btn-warning2[disabled]:hover, .btn-warning2[disabled]:focus, .btn-warning2[disabled]:active, .btn-warning2.active[disabled], fieldset[disabled] .btn-warning2, fieldset[disabled] .btn-warning2:hover, fieldset[disabled] .btn-warning2:focus, fieldset[disabled] .btn-warning2:active, fieldset[disabled] .btn-warning2.active {
  background-color: #d26f18;
  border-color: #d26f18; }

.btn-danger {
  background-color: var(--color3, #e74c3c);
  border-color: var(--color3, #e74c3c);
  color: #FFFFFF; }

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  background-color: var(--color3-lighten6, #e74c3c);
  border-color: var(--color3-lighten6, #e74c3c);
  color: #FFFFFF; }

.btn-danger:active, .btn-danger.active, .open .dropdown-toggle.btn-danger {
  background-image: none; }

.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger.active[disabled], fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: var(--color3-darken6, #e74c3c);
  border-color: var(--color3-darken6, #e74c3c); }

.btn-danger2 {
  background-color: #c0392b;
  border-color: #c0392b;
  color: #FFFFFF; }

.btn-danger2:hover, .btn-danger2:focus, .btn-danger2:active, .btn-danger2.active, .open .dropdown-toggle.btn-danger2 {
  background-color: #d24637;
  border-color: #d24637;
  color: #FFFFFF; }

.btn-danger2:active, .btn-danger2.active, .open .dropdown-toggle.btn-danger2 {
  background-image: none; }

.btn-danger2.disabled, .btn-danger2.disabled:hover, .btn-danger2.disabled:focus, .btn-danger2.disabled:active, .btn-danger2.disabled.active, .btn-danger2[disabled], .btn-danger2[disabled]:hover, .btn-danger2[disabled]:focus, .btn-danger2[disabled]:active, .btn-danger2.active[disabled], fieldset[disabled] .btn-danger2, fieldset[disabled] .btn-danger2:hover, fieldset[disabled] .btn-danger2:focus, fieldset[disabled] .btn-danger2:active, fieldset[disabled] .btn-danger2.active {
  background-color: #a73225;
  border-color: #a73225; }

.btn-link {
  color: inherit; }

.btn-link:hover, .btn-link:focus, .btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  color: var(--color4, #6a6c6f); }

.btn-link:active, .btn-link.active, .open .dropdown-toggle.btn-link {
  background-image: none; }

.btn-link.disabled, .btn-link.disabled:hover, .btn-link.disabled:focus, .btn-link.disabled:active, .btn-link.disabled.active, .btn-link[disabled], .btn-link[disabled]:hover, .btn-link[disabled]:focus, .btn-link[disabled]:active, .btn-link.active[disabled], fieldset[disabled] .btn-link, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus, fieldset[disabled] .btn-link:active, fieldset[disabled] .btn-link.active {
  color: var(--color4-lighten20, #6a6c6f); }

.btn-link-blue {
  color: #2eadec;
  font-weight: 600 !important;
  font-size: 14px; }

.btn-link-blue:hover, .btn-link-blue:focus, .btn-link-blue:active, .btn-link-blue.active, .open .dropdown-toggle.btn-link-blue {
  color: #8cd2f5; }

.btn-link-blue:active, .btn-link-blue.active, .open .dropdown-toggle.btn-link-blue {
  background-image: none; }

.btn-link-blue.disabled, .btn-link-blue.disabled:hover, .btn-link-blue.disabled:focus, .btn-link-blue.disabled:active, .btn-link-blue.disabled.active, .btn-link-blue[disabled], .btn-link-blue[disabled]:hover, .btn-link-blue[disabled]:focus, .btn-link-blue[disabled]:active, .btn-link-blue.active[disabled], fieldset[disabled] .btn-link-blue, fieldset[disabled] .btn-link-blue:hover, fieldset[disabled] .btn-link-blue:focus, fieldset[disabled] .btn-link-blue:active, fieldset[disabled] .btn-link-blue.active {
  color: #8cd2f5; }

.btn-outline {
  color: inherit;
  background-color: transparent;
  transition: all .5s; }

.btn-xs {
  border-radius: 3px;
  font-size: 11px;
  line-height: 1.5;
  padding: 1px 7px; }

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429; }

.btn-circle.btn-xs {
  width: 20px;
  height: 20px;
  padding: 4px 8px;
  border-radius: 25px;
  font-size: 10px;
  line-height: 1; }

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33; }

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33; }

.btn-ms {
  display: block;
  width: 100%;
  background-color: white;
  margin-top: 5px;
  transition: all .5s; }
  .btn-ms:hover {
    opacity: 0.8; }

.btn-reCaptcha {
  display: flex;
  justify-content: center;
  margin-top: -10px;
  padding-bottom: 5px; }

/* Lists */
.list .list-item-container {
  float: left; }

.list-item-container div:first-child {
  border-top: none; }

.list .list-item .list-item-content p {
  margin-bottom: 3px;
  margin-top: 0; }

.list .list-item-date {
  color: #AAAAAA;
  font-size: 10px;
  text-align: center; }

.list .list-item {
  float: left;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #e4e5e7; }

.list .list-item-container-small {
  min-width: 80px;
  text-align: center; }

.list .list-item .list-item-content .list-item-right {
  height: 100%;
  padding: 0 10px;
  position: absolute;
  right: 0;
  top: 0; }

.list .list-item .list-item-content h1, .list .list-item .list-item-content h2, .list .list-item .list-item-content h3, .list .list-item .list-item-content h4, .list .list-item .list-item-content h5, .list .list-item .list-item-content h6, .list .list-item .list-item-content .list-item-title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin: 0; }

a.list-group-item.active, a.list-group-item.active:hover, a.list-group-item.active:focus {
  background-color: #34495e;
  border-color: #34495e;
  color: #FFFFFF !important; }

.list-group-item-heading {
  margin-top: 10px; }

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  line-height: inherit; }

.list-group-item {
  background-color: inherit;
  border: 1px solid #e4e5e7;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative; }

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none; }

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none; }

.no-padding .list-group {
  margin-bottom: 0; }

/* Panels */
.hpanel > .panel-heading {
  color: inherit;
  font-weight: 600;
  padding: 10px 4px;
  transition: all 0.3s;
  border: 1px solid transparent; }

.hpanel .hbuilt.panel-heading {
  border-bottom: none; }

.hpanel > .panel-footer,
.hpanel > .panel-section {
  color: inherit;
  border: 1px solid #e4e5e7;
  border-top: none;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 15px; }

.hpanel > .panel-semifooter,
.hpanel > .panel-section {
  color: inherit;
  border: 1px solid #e4e5e7;
  border-top: none;
  font-size: 90%;
  background: #fafbfc;
  padding: 10px 15px; }

.hpanel.panel-collapse > .panel-heading,
.hpanel .hbuilt {
  background: #fff;
  border-color: #e4e5e7;
  border: 1px solid #e4e5e7;
  padding: 10px 10px;
  border-radius: 2px; }

.hpanel .panel-body {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 20px;
  position: relative; }

.hpanel .panel-body-round {
  background: #fff;
  border: 1px solid #e4e5e7;
  border-radius: 6px;
  padding: 20px;
  position: relative; }

.hpanel.panel-group .panel-body:first-child {
  border-top: 1px solid #e4e5e7; }

.hpanel.panel-group .panel-body {
  border-top: none; }

.panel-collapse .panel-body {
  border: none; }

.hpanel {
  background-color: none;
  border: none;
  box-shadow: none;
  margin-bottom: 25px; }

.panel-tools {
  display: inline-block;
  float: right;
  margin-top: 0;
  padding: 0;
  position: relative; }

.hpanel .alert {
  margin-bottom: 0;
  border-radius: 0;
  border: 1px solid #e4e5e7;
  border-bottom: none; }

.panel-tools a {
  margin-left: 5px;
  color: var(--color4-lighten20, #6a6c6f);
  cursor: pointer; }

.hpanel.hgreen .panel-body {
  border-top: 2px solid var(--color1, #62cb31); }

.hpanel.hblue .panel-body {
  border-top: 2px solid var(--color2, #3498db); }

.hpanel.hyellow .panel-body {
  border-top: 2px solid #ffb606; }

.hpanel.hviolet .panel-body {
  border-top: 2px solid #9b59b6; }

.hpanel.horange .panel-body {
  border-top: 2px solid #e67e22; }

.hpanel.hred .panel-body {
  border-top: 2px solid var(--color3, #e74c3c); }

.hpanel.hreddeep .panel-body {
  border-top: 2px solid #c0392b; }

.hpanel.hnavyblue .panel-body {
  border-top: 2px solid #34495e; }

.hpanel.hbggreen .panel-body {
  background: var(--color1, #62cb31);
  color: #fff;
  border: none; }

.hpanel.hbgblue .panel-body {
  background: var(--color2, #3498db);
  color: #fff;
  border: none; }

.hpanel.hbgyellow .panel-body {
  background: #ffb606;
  color: #fff;
  border: none; }

.hpanel.hbgviolet .panel-body {
  background: #9b59b6;
  color: #fff;
  border: none; }

.hpanel.hbgorange .panel-body {
  background: #e67e22;
  color: #fff;
  border: none; }

.hpanel.hbgred .panel-body {
  background: var(--color3, #e74c3c);
  color: #fff;
  border: none; }

.hpanel.hbgreddeep .panel-body {
  background: #c0392b;
  color: #fff;
  border: none; }

.hpanel.hbgnavyblue .panel-body {
  background: #34495e;
  color: #fff;
  border: none; }

.panel-group .panel-heading {
  background-color: #f7f9fa; }

.small-header .hpanel {
  margin-bottom: 0; }

.small-header {
  padding: 0 !important; }

.small-header .panel-body {
  padding: 15px 25px;
  border-right: none;
  border-left: none;
  border-top: none;
  border-radius: 0; }

.panel-body h5,
.panel-body h4 {
  font-weight: 600; }

.small-header .panel-body h2 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 0 0 0; }

.small-header .panel-body small {
  color: var(--color4-lighten10, #6a6c6f); }

.hbreadcrumb {
  padding: 2px 0px;
  margin-top: 6px;
  margin-bottom: 0px;
  list-style: none;
  background-color: #fff;
  font-size: 11px; }
  .hbreadcrumb > li {
    display: inline-block; }
    .hbreadcrumb > li + li:before {
      padding: 0 5px;
      color: #34495e; }
  .hbreadcrumb > .active {
    color: var(--color4-lighten20, #6a6c6f); }

.wrapper {
  padding: 10px 20px; }

.hpanel.collapsed .panel-body,
.hpanel.collapsed .panel-footer {
  display: none; }

.hpanel.collapsed .fa.fa-chevron-up:before {
  content: "\f078"; }

.hpanel.collapsed .fa.fa-chevron-down:before {
  content: "\f077"; }

.hpanel.collapsed.panel-collapse .panel-body {
  border-width: 0 1px 1px 1px;
  border-color: #e4e5e7;
  border-style: solid; }

.hpanel.collapsed .hbuilt.panel-heading {
  border-bottom: 1px solid #e4e5e7; }

body.fullscreen-panel-mode {
  overflow-y: hidden; }

.hpanel.fullscreen {
  z-index: 2030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin-bottom: 0; }

.hpanel.fullscreen .showhide {
  display: none; }

.hpanel.fullscreen .panel-body {
  min-height: calc(100% - 77px); }

.panel-group-dasboard-financeira {
  margin-top: 30px; }

.panel-collapse-dasboard-financeira {
  max-height: 500px;
  overflow: hidden;
  transition: max-height 0.5s ease; }

.panel-close-dasboard-financeira {
  max-height: 0; }

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  border: 1px solid #e4e5e7;
  border-bottom-color: transparent; }

.nav-tabs {
  border-bottom: 1px solid #e4e5e7; }

.tab-pane .panel-body {
  border-top: none; }

.tabs-left .tab-pane .panel-body, .tabs-right .tab-pane .panel-body {
  border-top: 1px solid #e4e5e7; }

.nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent; }

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0; }

.tabs-left .panel-body {
  position: static; }

.tabs-left > .nav-tabs, .tabs-right > .nav-tabs {
  width: 20%; }

.tabs-left .panel-body {
  width: 80%;
  margin-left: 20%; }

.tabs-right .panel-body {
  width: 80%;
  margin-right: 20%; }

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none; }

.tab-content > .active,
.pill-content > .active {
  display: block; }

.tabs-below > .nav-tabs {
  border-top: 1px solid #e4e5e7; }

.tabs-below > .nav-tabs > li {
  margin-top: -1px;
  margin-bottom: 0; }

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px; }

.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #e4e5e7;
  border-bottom-color: transparent; }

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none; }

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px; }

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px; }

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px; }

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  border-color: #e4e5e7 transparent #e4e5e7 #e4e5e7;
  *border-right-color: #ffffff; }

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px; }

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0; }

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd; }

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #e4e5e7 #e4e5e7 #e4e5e7 transparent;
  *border-left-color: #ffffff;
  z-index: 1; }

.tooltip-inner {
  background-color: #34495e; }

.tooltip.top .tooltip-arrow {
  border-top-color: #34495e; }

.tooltip.right .tooltip-arrow {
  border-right-color: #34495e; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #34495e; }

.tooltip.left .tooltip-arrow {
  border-left-color: #34495e; }

.tooltipLabel {
  background: var(--color4-lighten10, #6a6c6f) !important; }

.rt-expandable {
  cursor: unset !important; }

.div-expander {
  display: flex;
  justify-content: center;
  align-items: center; }

.div-expander span {
  font-size: 14px;
  color: black; }

.show-pointer {
  cursor: pointer !important; }

.stats h4, h5, h6 {
  margin: 5px 0; }

.stats-title h3 {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600; }

.stats-title h4 {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600; }

.stats-title h5 {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600; }

.stats-title h6 {
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 600; }

.stats-box div {
  display: inline-block;
  vertical-align: middle; }

.stats-label {
  line-height: 1.2; }

.stats-label small {
  font-size: 80%;
  line-height: 0.6; }

.stats-box-value {
  background: white; }

.stats-box-detail {
  background: #34495e;
  color: #fff;
  position: relative; }

.stats-underline {
  text-decoration: underline !important; }

.plan-box {
  -ms-transform: scale(0.85);
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0.7;
  transition: all 0.2s ease 0s; }

.plan-box.active, .plan-box:hover {
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1; }

.chat-avatar {
  width: 36px;
  height: 36px;
  float: left;
  margin-right: 10px;
  border-radius: 50%; }

.chat-user-name {
  padding: 10px; }

.chat-user {
  padding: 8px 10px;
  border-bottom: 1px solid #e7eaec; }

.chat-user a {
  color: inherit; }

.chat-view {
  z-index: 1070; }

.chat-users, .chat-statistic {
  margin-left: -30px; }

@media (max-width: 992px) {
  .chat-users, .chat-statistic {
    margin-left: 0px; } }

.chat-view .ibox-content {
  padding: 0; }

.chat-message {
  padding: 10px 20px; }

.message-avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  margin-top: 1px; }

.chat-discussion .chat-message .message-avatar {
  float: left;
  margin-right: 10px; }

.chat-discussion .message {
  background-color: var(--color5-lighten2, #f1f3f6);
  text-align: left;
  display: block;
  padding: 10px 20px;
  position: relative;
  border-radius: 4px; }

.chat-discussion .chat-message .message-date {
  float: right; }

.chat-discussion .chat-message .message {
  text-align: left;
  margin-left: 55px; }

.message-date {
  font-size: 10px;
  color: #888888; }

.message-content {
  display: block; }

.chat-users, .chat-discussion {
  overflow-y: auto;
  height: 500px; }

.chat-discussion .chat-message.left .message-date {
  float: right; }

.chat-discussion .chat-message.right .message-date {
  float: left; }

.chat-discussion .chat-message.left .message {
  text-align: left;
  margin-left: 55px; }

.chat-discussion .chat-message.right .message {
  text-align: right;
  margin-right: 55px; }

.chat-discussion .chat-message.left .message-avatar {
  float: left;
  margin-right: 10px; }

.chat-discussion .chat-message.right .message-avatar {
  float: right;
  margin-left: 10px; }

.chat-discussion {
  padding: 15px 10px; }

.projects .project-label {
  font-weight: 600; }

.projects .hpanel .panel-body {
  border-bottom: none; }

.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7; }

.projects .hpanel:last-child .panel-body {
  border-bottom: 1px solid #e4e5e7; }

.projects .hpanel h4 a {
  color: var(--color4, #6a6c6f); }

.projects .project-people img {
  width: 28px;
  height: 28px;
  margin-left: 2px;
  margin-bottom: 4px; }

.projects .project-info {
  text-align: right; }

@media (max-width: 768px) {
  .projects .project-info {
    text-align: left; } }

.social-board .social-profile img {
  height: 42px;
  width: 42px;
  border-radius: 50%; }

.social-board .social-profile h5 {
  margin-top: 5px;
  margin-bottom: 0px; }

.social-board .social-form {
  margin: 10px 0; }

.social-board .social-form input {
  font-size: 12px;
  padding: 2px 12px;
  height: 28px; }

.social-talk .social-profile img {
  height: 32px;
  width: 32px;
  border-radius: 50%; }

.social-talk .text-muted {
  color: var(--color4-lighten25, #6a6c6f); }

.social-talk {
  margin-bottom: 10px; }

.social-talk .social-content {
  margin-top: 2px; }

.blog-box p {
  font-size: 12px; }

.blog-box h4 {
  font-weight: bold;
  margin-top: 0; }

.blog-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 10px 20px;
  position: relative;
  font-weight: 500; }

.blog-box .panel-footer {
  color: var(--color4-lighten15, #6a6c6f); }

.blog-box .panel-heading .media-body {
  line-height: 16px; }

.blog-box .panel-heading .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%; }

.blog-box .panel-heading .media-body h5 {
  margin-top: 0; }

.blog-box .panel-image {
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  position: relative; }

.blog-box .panel-image .title {
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  color: #fff; }

.blog-box .panel-image .title h4 {
  margin-bottom: 0px; }

.blog-box .panel-image .title a {
  color: #fff !important; }

.blog-article-box .panel-heading {
  background: #fff;
  border-top: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  border-right: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 80px 10px;
  position: relative;
  font-weight: 500;
  text-align: center; }

.blog-article-box .panel-heading h4 {
  font-size: 32px;
  font-weight: 700;
  color: var(--color4-darken10, #6a6c6f); }

.blog-article-box .panel-body {
  padding: 50px 70px; }

.blog-article-box .panel-body p {
  font-family: "Times New Roman", Georgia, Serif;
  font-size: 20px;
  line-height: 32px;
  color: var(--color4-darken10, #6a6c6f); }

@media (max-width: 768px) {
  .blog-article-box .panel-body {
    padding: 10px 20px; }
  .blog-article-box .panel-body p {
    font-family: "Times New Roman", Georgia, Serif;
    font-size: 16px;
    line-height: 28px; }
  .blog-article-box .panel-heading {
    padding: 20px 20px; } }

.forum-box .panel-body {
  border-bottom: none; }

.forum-box .panel-body:last-child {
  border-bottom: 1px solid #e4e5e7; }

.forum-box .forum-heading h4 {
  margin-bottom: 3px;
  font-weight: 600;
  margin-top: 5px;
  display: inline; }

.forum-box .forum-heading .label {
  margin-right: 5px; }

.forum-box .forum-heading .desc {
  color: var(--color4-lighten15, #6a6c6f);
  font-size: 12px; }

.forum-box .panel-heading {
  border: 1px solid #e4e5e7;
  border-bottom: none;
  font-size: 90%;
  background: #f7f9fa;
  padding: 10px 20px; }

.forum-box .forum-info {
  text-align: right;
  line-height: 20px; }
  .forum-box .forum-info .number {
    display: block;
    font-weight: 600;
    font-size: 22px;
    margin-top: 5px; }
  .forum-box .forum-info span {
    font-size: 12px; }

.forum-box .panel-body .media-image {
  text-align: center;
  margin-right: 10px;
  min-width: 100px; }

.forum-box .panel-body .media img {
  margin-top: 10px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-bottom: 10px; }

.forum-box .author-info {
  font-size: 12px; }

.forum-box .forum-comments {
  border: 1px solid #e4e5e7;
  border-left: none;
  border-right: none;
  background: #f7f9fa;
  margin-top: 20px;
  padding: 10px 15px; }

.forum-box .forum-comments .media img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 0;
  margin-bottom: 10px; }

.forum-box .forum-comments form {
  margin-top: 10px; }

@media (max-width: 768px) {
  .forum-box .forum-info {
    text-align: left; } }

ul.mailbox-list, ul.h-list {
  list-style: none;
  padding: 0; }

ul.mailbox-list li a, ul.h-list li a {
  padding: 10px 15px;
  display: block; }

ul.mailbox-list li a i, ul.h-list li a i {
  margin-right: 6px;
  display: inline-block; }

ul.mailbox-list li.active a, ul.h-list li.active a {
  background: #f7f9fa;
  border: 1px solid #e4e5e7;
  font-weight: 600;
  border-radius: 1px; }

ul.mailbox-list li:hover a, ul.h-list li:hover a {
  font-weight: 600; }

.table-mailbox .radio, .table-mailbox .checkbox {
  margin: 0; }

.table-mailbox .radio label, .table-mailbox .checkbox label {
  cursor: default; }

.table-mailbox tr.unread td a {
  font-weight: 600; }

.table-mailbox tr td span.label {
  margin-left: 10px; }

.table-mailbox tr:first-child td {
  border-top: none; }

.table-mailbox tr td:first-child {
  width: 33px;
  padding-right: 0; }

.mailbox-pagination {
  text-align: right; }

@media (max-width: 768px) {
  .mailbox-pagination {
    text-align: left; } }

.email-compose .note-editor {
  border: none; }

.email-compose .note-editor .note-editable {
  padding: 15px 25px; }

.email-compose .note-toolbar {
  padding: 5px 15px; }

.note-popover .popover .popover-content > .btn-group, .note-toolbar > .btn-group {
  margin-bottom: 5px; }

.note-editor .note-toolbar {
  /* Background from footer panel */
  background-color: #f7f9fa; }

.contact-panel h3 {
  margin-bottom: 2px; }

.contact-panel h3 a {
  color: inherit; }

.contact-panel p {
  font-size: 11px;
  line-height: 16px;
  margin-bottom: 0; }

.contact-panel img {
  width: 76px;
  height: 76px; }

.contact-footer {
  padding: 0 15px; }

.contact-stat span {
  font-size: 10px;
  font-weight: 500;
  display: block;
  color: var(--color4-lighten20, #6a6c6f);
  text-transform: uppercase; }

.contact-stat {
  padding: 8px 0px;
  text-align: center; }

.contact-panel:hover .panel-body {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: pulse;
  -webkit-animation-name: pulse;
  z-index: 1; }

.hpanel .panel-body.file-body {
  padding: 30px 0;
  text-align: center; }

.file-body i.fa {
  font-size: 56px; }

body.blank {
  background-color: var(--color5, #f1f3f6); }

.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

.login-form {
  padding-top: 30px;
  width: 420px; }

.register-container {
  max-width: 720px;
  margin: auto;
  padding-top: 6%; }

.lock-container {
  max-width: 420px;
  margin: auto;
  padding-top: 6%; }

.error-container {
  max-width: 620px;
  margin: auto;
  padding-top: 6%; }

.big-icon {
  font-size: 120px; }

.error-container h1 {
  font-size: 44px; }

.back-link {
  float: left;
  width: 100%;
  margin: 10px; }

.login-background {
  background-size: cover;
  height: 100vh; }

.login-img-container {
  text-align: center; }
  .login-img-container img {
    height: 150px;
    width: auto; }

@media (max-width: 420px) {
  .login-container,
  .lock-container {
    margin: auto 10px; } }

@media (max-width: 720px) {
  .register-container {
    margin: auto 10px; }
  .lock-container {
    padding-top: 15%; } }

@media (max-width: 620px) {
  .error-container {
    margin: auto 10px; } }

@media (min-width: 991px) {
  #notes {
    display: block;
    height: auto !important;
    visibility: visible; } }

#notes .panel-body:first-child {
  border-top: none; }

.note-content textarea {
  border: none;
  width: 100%;
  padding: 0;
  min-height: 360px; }

.note-link h5 {
  margin-top: 0; }

.note-link:hover {
  background: #f7f9fa; }

.filter-item {
  margin-bottom: 0; }
  .filter-item .panel-body {
    padding: 10px 20px;
    border-bottom: none; }
  .filter-item a {
    color: var(--color4, #6a6c6f); }

.filter-item:last-child {
  border-bottom: 1px solid #e4e5e7; }

.filter-item:hover .panel-body, .filter-item.active .panel-body {
  background: #fafcfd;
  z-index: 100;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.175); }

.filter-item::selection .panel-body {
  background: #fafcfd; }

.filter-item h4 {
  margin-bottom: 5px; }

.sweet-alert button {
  background-color: #34495e;
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer; }

.sweet-alert button:focus {
  outline: none;
  box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05); }

.sweet-alert button:hover {
  background-color: #a1d9f2; }

.sweet-alert button:active {
  background-color: #81ccee; }

.sweet-alert button.cancel {
  background-color: #D0D0D0; }

.sweet-alert button.cancel:hover {
  background-color: #c8c8c8; }

.sweet-alert button.cancel:active {
  background-color: #b6b6b6; }

.sweet-alert p {
  font-size: 14px;
  font-weight: 400; }

.sweet-alert h2 {
  font-size: 26px; }

.sweet-alert {
  padding: 25px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2); }

.cg-notify-message.homer-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1 px rgba(0, 0, 0, 0.1), 0 2 px 4 px rgba(0, 0, 0, 0.2);
  border: none;
  margin-top: 30px;
  color: inherit; }

.homer-notify.alert-warning {
  border-left: 6px solid #ffb606; }

.homer-notify.alert-success {
  border-left: 6px solid var(--color1, #62cb31); }

.homer-notify.alert-danger {
  border-left: 6px solid #c0392b; }

.homer-notify.alert-info {
  border-left: 6px solid var(--color2, #3498db); }

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
  margin: 10px auto 0; }

#toast-container > .toast-info,
#toast-container > .toast-error,
#toast-container > .toast-warning,
#toast-container > .toast-success {
  background-image: none; }

#toast-container > div {
  background: #fff;
  padding: 20px;
  color: #6a6c6f;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 1; }

#toast-container > div:hover {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2); }

.toast-close-button {
  color: #000;
  opacity: 0.2; }

.toast-info {
  background: #fff;
  border-left: 6px solid #3498db; }

.toast-success {
  background: #fff;
  border-left: 6px solid #62cb31; }

.toast-warning {
  background: #fff;
  border-left: 6px solid #ffb606; }

.toast-error {
  background: #fff;
  border-left: 6px solid #e74c3c; }

.toast-progress {
  opacity: 0.6; }

.toast-info .toast-progress {
  background-color: #3498db; }

.toast-success .toast-progress {
  background-color: #62cb31; }

.toast-warning .toast-progress {
  background-color: #ffb606; }

.toast-error .toast-progress {
  background-color: #e74c3c; }

.tour-backdrop {
  opacity: 0.5; }

/* FULLCALENDAR */
.fc-state-default {
  background-color: #ffffff;
  background-image: none;
  background-repeat: repeat-x;
  box-shadow: none;
  color: inherit;
  text-shadow: none; }

.fc-state-default {
  border: 1px solid; }

.fc-button {
  color: inherit;
  border: 1px solid #e4e5e7;
  cursor: pointer;
  display: inline-block;
  height: 1.9em;
  line-height: 1.9em;
  overflow: hidden;
  padding: 0 0.6em;
  position: relative;
  white-space: nowrap; }

.fc-state-active {
  background-color: #34495e;
  border-color: #34495e;
  color: #ffffff; }

.fc-header-title h2 {
  font-size: 16px;
  font-weight: 600;
  color: inherit; }

.fc-content .fc-widget-header,
.fc-content .fc-widget-content {
  border-color: #e4e5e7;
  font-weight: normal; }

.fc-border-separate tbody {
  background-color: #F8F8F8; }

.fc-state-highlight {
  background: none repeat scroll 0 0 var(--color5, #f1f3f6); }

.external-event {
  padding: 5px 10px;
  border-radius: 2px;
  cursor: pointer;
  margin-bottom: 5px; }

.fc-ltr .fc-event-hori.fc-event-end,
.fc-rtl .fc-event-hori.fc-event-start {
  border-radius: 2px; }

.fc-event,
.fc-agenda .fc-event-time,
.fc-event a {
  padding: 4px 6px;
  background-color: #34495e;
  border-color: #34495e; }

.fc-event-time,
.fc-event-title {
  color: #717171;
  padding: 0 1px; }

.ui-calendar .fc-event-time,
.ui-calendar .fc-event-title {
  color: #fff; }

.fc-view-container .fc-widget-header {
  background: var(--color5-lighten2, #f1f3f6); }

.fc-widget-header .fc-day-header {
  padding: 12px 0; }

.fc-ltr .fc-basic-view .fc-day-number {
  text-align: center; }

.fc-basic-view td.fc-day-number, .fc-basic-view td.fc-week-number span {
  padding: 6px; }

.fc-day-grid-event {
  margin: 2px 6px 0; }

.CodeMirror {
  border: 1px solid #eee;
  height: auto; }

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto; }

.flot-chart {
  display: block;
  height: 300px;
  position: relative; }

.flot-chart-content {
  width: 100%;
  height: 100%; }

.stats .flot-chart {
  height: 60px; }

.note-editor, .note-editor.note-frame {
  border: 1px solid #e4e5e7; }

.note-editor .note-toolbar {
  border-bottom: 1px solid #e4e5e7; }

.note-editor .btn-default {
  border-color: #e4e5e7; }

.note-editor .panel-heading {
  padding: 5px 10px; }

.airmode .note-editor {
  border: none; }

.card {
  font-size: 12px; }

.card > .header {
  border-bottom: 1px solid gray;
  font-size: 14px;
  text-align: center; }

.popup-content {
  width: 50vw !important; }

.popup-content-content {
  width: 65vw !important; }

.Alcenter {
  text-align: center;
  font-size: 15px; }

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px; }

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none; }

.dd-list .dd-list {
  padding-left: 30px; }

.dd-collapsed .dd-list {
  display: none; }

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px; }

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #e4e5e7;
  background: #f7f9fa;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-handle span {
  font-weight: bold; }

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold; }

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold; }

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0; }

.dd-item > button[data-action="collapse"]:before {
  content: '-'; }

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1; }

#nestable2 .dd-item > button:before {
  content: "\f067"; }

#nestable2 .dd-item > button[data-action="collapse"]:before {
  content: "\f068"; }

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #e4e5e7;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px; }

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none; }

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0; }

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); }

/**
* Nestable Extras
*/
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #e4e5e7;
  border-bottom: 2px solid #e4e5e7; }

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0; }

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed #e4e5e7;
  background: #f7f9fa;
  padding: 10px; }

#nestable2 .dd-handle:hover {
  /*background: #bbb;*/ }

#nestable2 span.label {
  margin-right: 10px; }

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box; }

.angular-ui-tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  font-weight: normal; }

.angular-ui-tree-handle:hover {
  font-weight: bold;
  cursor: pointer; }

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.group-title {
  background-color: #687074 !important;
  color: #FFF !important; }

.tree-node {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px;
  border-radius: 3px; }

.tree-node .btn {
  min-width: 22px;
  margin-right: 4px; }

.tree-node-content {
  margin: 5px 5px 5px 0; }

.tree-handle {
  background: none repeat scroll 0 0 #f7f9fa;
  border: 1px dashed #e4e5e7;
  color: inherit;
  padding: 10px; }

.angular-ui-tree-placeholder {
  background: #f0f9ff;
  border: 1px dashed #e4e5e7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

/* iCheck plugin Square skin, green
----------------------------------- */
.icheckbox_square-green,
.iradio_square-green {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  width: 22px;
  height: 22px;
  background: url("img/green.png") no-repeat;
  border: none;
  cursor: pointer; }

.icheckbox_square-green {
  background-position: 0 0; }

.icheckbox_square-green.hover {
  background-position: -24px 0; }

.icheckbox_square-green.checked {
  background-position: -48px 0; }

.icheckbox_square-green.disabled {
  background-position: -72px 0;
  cursor: default; }

.icheckbox_square-green.checked.disabled {
  background-position: -96px 0; }

.iradio_square-green {
  background-position: -120px 0; }

.iradio_square-green.hover {
  background-position: -144px 0; }

.iradio_square-green.checked {
  background-position: -168px 0; }

.iradio_square-green.disabled {
  background-position: -192px 0;
  cursor: default; }

.iradio_square-green.checked.disabled {
  background-position: -216px 0; }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5 / 4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
  .icheckbox_square-green,
  .iradio_square-green {
    background-image: url("img/green$2x.png");
    -webkit-background-size: 240px 24px;
    background-size: 240px 24px; } }

.ui-sortable .panel-body {
  cursor: move; }

.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #f7f9fa; }

.ibox.ui-sortable-placeholder {
  margin: 0px 0px 23px !important; }

.bg-white {
  background-color: #fff; }

.bg-light-soft {
  background-color: var(--color5, #f1f3f6); }

.bg-light {
  background-color: #f7f9fa; }

.border-top {
  border-top: 1px solid #e4e5e7; }

.border-bottom {
  border-bottom: 1px solid #e4e5e7; }

.border-right {
  border-right: 1px solid #e4e5e7; }

.border-left {
  border-left: 1px solid #e4e5e7; }

.h-bg-navy-blue {
  background: #34495e; }

.h-bg-violet {
  background: #9b59b6; }

.h-bg-blue {
  background: var(--color2, #3498db); }

.h-bg-green {
  background: var(--color1, #62cb31); }

.h-bg-yellow {
  background: #ffb606; }

.h-bg-orange {
  background: #e67e22; }

.h-bg-red {
  background: var(--color3, #e74c3c); }

.h-bg-red-deep {
  background: #c0392b; }

.text-primary {
  color: #34495e; }

.text-primary-2 {
  color: #9b59b6; }

.text-info {
  color: var(--color2, #3498db); }

.text-success {
  color: var(--color1, #62cb31); }

.text-warning {
  color: #ffb606; }

.text-danger {
  color: var(--color3, #e74c3c); }

.text-white {
  color: #fff; }

/* MARGINS & PADDINGS */
.p-xxs {
  padding: 5px !important; }

.p-xs {
  padding: 10px !important; }

.p-sm {
  padding: 15px !important; }

.p-m {
  padding: 20px !important; }

.p-md {
  padding: 25px !important; }

.p-lg {
  padding: 30px !important; }

.p-xl {
  padding: 40px !important; }

.m-xxs {
  margin: 2px 4px; }

.m-xs {
  margin: 5px; }

.m-sm {
  margin: 10px; }

.m {
  margin: 15px; }

.m-md {
  margin: 20px; }

.m-lg {
  margin: 30px; }

.m-xl {
  margin: 50px; }

.m-n {
  margin: 0 !important; }

.m-l-none {
  margin-left: 0; }

.m-l-xs {
  margin-left: 5px; }

.m-l-sm {
  margin-left: 10px; }

.m-l {
  margin-left: 15px; }

.m-l-md {
  margin-left: 20px; }

.m-l-lg {
  margin-left: 30px; }

.m-l-xl {
  margin-left: 40px; }

.m-l-n-xxs {
  margin-left: -1px; }

.m-l-n-xs {
  margin-left: -5px; }

.m-l-n-sm {
  margin-left: -10px; }

.m-l-n {
  margin-left: -15px; }

.m-l-n-md {
  margin-left: -20px; }

.m-l-n-lg {
  margin-left: -30px; }

.m-l-n-xl {
  margin-left: -40px; }

.m-t-none {
  margin-top: 0; }

.m-t-xxs {
  margin-top: 1px; }

.m-t-xs {
  margin-top: 5px; }

.m-t-sm {
  margin-top: 10px; }

.m-t {
  margin-top: 15px; }

.m-t-md {
  margin-top: 20px; }

.m-t-lg {
  margin-top: 30px; }

.m-t-xl {
  margin-top: 40px; }

.m-t-xxl {
  margin-top: 50px; }

.m-t-xxxl {
  margin-top: 60px; }

.m-t-n-xxs {
  margin-top: -1px; }

.m-t-n-xs {
  margin-top: -5px; }

.m-t-n-sm {
  margin-top: -10px; }

.m-t-n {
  margin-top: -15px; }

.m-t-n-md {
  margin-top: -20px; }

.m-t-n-lg {
  margin-top: -30px; }

.m-t-n-xl {
  margin-top: -40px; }

.m-r-none {
  margin-right: 0; }

.m-r-xxs {
  margin-right: 1px; }

.m-r-xs {
  margin-right: 5px; }

.m-r-sm {
  margin-right: 10px; }

.m-r {
  margin-right: 15px; }

.m-r-md {
  margin-right: 20px; }

.m-r-lg {
  margin-right: 30px; }

.m-r-xl {
  margin-right: 40px; }

.m-r-n-xxs {
  margin-right: -1px; }

.m-r-n-xs {
  margin-right: -5px; }

.m-r-n-sm {
  margin-right: -10px; }

.m-r-n {
  margin-right: -15px; }

.m-r-n-md {
  margin-right: -20px; }

.m-r-n-lg {
  margin-right: -30px; }

.m-r-n-xl {
  margin-right: -40px; }

.m-b-none {
  margin-bottom: 0; }

.m-b-xxs {
  margin-bottom: 1px; }

.m-b-xs {
  margin-bottom: 5px; }

.m-b-sm {
  margin-bottom: 10px; }

.m-b {
  margin-bottom: 15px; }

.m-b-md {
  margin-bottom: 20px; }

.m-b-lg {
  margin-bottom: 30px; }

.m-b-xl {
  margin-bottom: 40px; }

.m-b-n-xxs {
  margin-bottom: -1px; }

.m-b-n-xs {
  margin-bottom: -5px; }

.m-b-n-sm {
  margin-bottom: -10px; }

.m-b-n {
  margin-bottom: -15px; }

.m-b-n-md {
  margin-bottom: -20px; }

.m-b-n-lg {
  margin-bottom: -30px; }

.m-b-n-xl {
  margin-bottom: -40px; }

.space-15 {
  margin: 15px 0; }

.space-20 {
  margin: 20px 0; }

.space-25 {
  margin: 25px 0; }

.space-30 {
  margin: 30px 0; }

/* Layout */
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden !important; }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #fff;
  font-size: 13px;
  color: var(--color4, #6a6c6f);
  margin: 0;
  padding: 0;
  height: 100%; }

#header {
  background-color: #fff;
  display: block;
  height: 62px;
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #e4e5e7; }

#menu {
  width: 180px;
  bottom: 0;
  float: left;
  left: 0;
  position: absolute;
  top: 62px;
  transition: margin 0.4s ease 0s; }

#wrapper {
  margin: 0 0 0 var(--width0, 180px);
  padding: 0;
  background: var(--color5, #f1f3f6);
  border-left: 1px solid #e4e5e7;
  transition: margin 0.4s ease 0s;
  position: relative;
  min-height: 100%; }

.content {
  padding: 25px 40px 40px 40px;
  min-width: 320px; }

.footer {
  padding: 10px 18px;
  background: #ffffff;
  border-top: 1px solid #e4e5e7;
  transition: margin 0.4s ease 0s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0; }

@media (max-width: 768px) {
  .content {
    padding: 15px 15px 15px 15px;
    min-width: 320px; } }

.content-boxed {
  max-width: 1100px;
  margin: auto;
  padding-top: 60px; }

#page-header {
  padding: 20px 40px; }

.fixed-navbar #header {
  width: 100%;
  position: fixed;
  z-index: 1000; }

.fixed-navbar #wrapper {
  top: 62px; }

.fixed-sidebar #menu {
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 197px; }

.fixed-sidebar.show-sidebar #menu {
  width: 180px; }

.sidebar-scroll #menu {
  position: fixed; }

.fixed-footer .footer {
  z-index: 1001;
  bottom: 0;
  right: 0; }

.fixed-footer.hide-sidebar .footer {
  left: 0; }

@media (max-width: 768px) {
  .fixed-footer .footer {
    left: 0; }
  .fixed-footer.show-sidebar .footer {
    left: 181px; } }

#right-sidebar {
  background-color: #fff;
  border-left: 1px solid #e4e5e7;
  position: fixed;
  top: 0;
  width: 260px !important;
  z-index: 1009;
  bottom: 0;
  right: 0;
  display: none;
  overflow: auto; }

#right-sidebar.sidebar-open {
  display: block; }

.fixed-small-header .small-header {
  position: fixed;
  top: 62px;
  left: 0;
  right: 0;
  margin-left: 181px;
  z-index: 100; }

.fixed-small-header .content {
  padding-top: 80px; }

.fixed-small-header.hide-sidebar .small-header {
  margin-left: 0; }

.fixed-small-header.page-small .small-header {
  margin-left: 0; }

.fixed-small-header.page-small .content {
  padding-top: 120px; }

.fixed-small-header.page-small.show-sidebar .small-header {
  margin-left: 181px;
  overflow: hidden; }

.boxed {
  background-color: #ffffff; }
  .boxed .profile-picture {
    background-color: white; }
  .boxed .boxed-wrapper {
    display: block;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55);
    -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.55); }

.ngHeaderCell {
  border-right: none;
  border-bottom: 1px solid #e4e5e7; }

.ngCell {
  border-right: none; }

.ngTopPanel {
  background: #fff; }

.ngRow.even {
  background: #f9f9f9; }

.ngRow.selected {
  background: var(--color5-darken4, #f1f3f6); }

.ngRow {
  border-bottom: 1px solid #e4e5e7; }

.ngCell {
  background-color: transparent; }

.ngHeaderCell {
  border-right: none; }

.gridStyle {
  border: 1px solid #e4e5e7;
  height: 400px; }

.show-grid [class^="col-"] {
  background-color: var(--color5, #f1f3f6) !important;
  border: 1px solid #ddd;
  padding-bottom: 10px;
  padding-top: 10px; }

.show-grid {
  margin: 15px 0; }

.h-50 {
  min-height: 50px; }

.h-100 {
  min-height: 100px; }

.h-150 {
  min-height: 150px; }

.h-200 {
  min-height: 200px; }

.h-300 {
  min-height: 300px; }

.h-400 {
  min-height: 400px; }

.h-500 {
  min-height: 500px; }

.h-600 {
  min-height: 600px; }

.w-xs {
  min-width: 100px; }

.w-sm {
  min-width: 120px; }

.w-m {
  min-width: 140px; }

.w-lg {
  min-width: 160px; }

.w-xl {
  min-width: 180px; }

.w-xxl {
  min-width: 200px; }

/* Fonts */
.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-bold {
  font-weight: 600; }

.font-extra-bold {
  font-weight: 700; }

.font-uppercase {
  text-transform: uppercase; }

.font-trans {
  opacity: 0.5 !important; }

.text-big {
  font-size: 60px; }

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 200; }

.color-line {
  background: #f7f9fa;
  height: 6px;
  background-image: -webkit-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, var(--color2, #3498db) 35%, var(--color2, #3498db) 45%, var(--color1, #62cb31) 45%, var(--color1, #62cb31) 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, var(--color3, #e74c3c) 85%, var(--color3, #e74c3c) 85%, #c0392b 85%, #c0392b 100%);
  background-image: -moz-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, var(--color2, #3498db) 35%, var(--color2, #3498db) 45%, var(--color1, #62cb31) 45%, var(--color1, #62cb31) 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, var(--color3, #e74c3c) 85%, var(--color3, #e74c3c) 85%, #c0392b 85%, #c0392b 100%);
  background-image: -ms-linear-gradient(left, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, var(--color2, #3498db) 35%, var(--color2, #3498db) 45%, var(--color1, #62cb31) 45%, var(--color1, #62cb31) 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, var(--color3, #e74c3c) 85%, var(--color3, #e74c3c) 85%, #c0392b 85%, #c0392b 100%);
  background-image: linear-gradient(to right, #34495e, #34495e 25%, #9b59b6 25%, #9b59b6 35%, var(--color2, #3498db) 35%, var(--color2, #3498db) 45%, var(--color1, #62cb31) 45%, var(--color1, #62cb31) 55%, #ffb606 55%, #ffb606 65%, #e67e22 65%, #e67e22 75%, var(--color3, #e74c3c) 85%, var(--color3, #e74c3c) 85%, #c0392b 85%, #c0392b 100%);
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat; }

.color-line-ereg {
  background: #f7f9fa;
  width: 100%;
  height: 6px;
  background-image: -webkit-linear-gradient(left, #005baa 0%, #005baa 33%, #97c01d 33%, #97c01d 66%, #f2920a 66%, #f2920a 100%);
  background-image: -moz-linear-gradient(left, #005baa 0%, #005baa 33%, #97c01d 33%, #97c01d 66%, #f2920a 66%, #f2920a 100%);
  background-image: -ms-linear-gradient(left, #005baa 0%, #005baa 33%, #97c01d 33%, #97c01d 66%, #f2920a 66%, #f2920a 100%);
  background-image: linear-gradient(to right, #005baa 0%, #005baa 33%, #97c01d 33%, #97c01d 66%, #f2920a 66%, #f2920a 100%);
  background-size: 100% 6px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0; }

/* Opacity */
.opacity-0 {
  opacity: 0; }

.opacity-1 {
  opacity: 1; }

/* Fix modal position */
body.modal-open {
  left: 0;
  right: 0;
  padding-right: 0 !important;
  position: absolute;
  height: initial; }

/* Google Maps */
.google-map {
  height: 300px; }

.hr-line-dashed {
  background-color: transparent;
  border-top: 1px dotted var(--color5, #f1f3f6);
  color: transparent;
  height: 1px;
  margin: 20px 0; }

.animated-panel {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

#logo {
  float: left;
  width: 180px;
  background-color: #34495e;
  padding: 8px 10px 18px 18px;
  height: 56px;
  text-align: center; }

#logo span {
  font-weight: 600;
  color: #fff;
  font-size: 14px; }

#logo.light-version {
  background-color: #f7f9fa;
  border-bottom: 1px solid #e4e5e7;
  text-align: center; }

#logo.light-version span {
  font-weight: 600;
  color: #34495e;
  font-size: 14px; }

.profile-picture {
  padding: 20px 20px;
  text-align: center; }

/* Labels */
.label-menu-corner .label {
  font-size: 10px;
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: 18px;
  top: 12px; }

/* Menu */
#side-menu {
  background: #f7f9fa; }

#side-menu li a {
  color: var(--color4, #6a6c6f);
  text-transform: uppercase;
  font-weight: 700;
  padding: 15px 20px; }

#side-menu li {
  border-bottom: 1px solid #e4e5e7; }

#side-menu li:first-child {
  border-top: 1px solid #e4e5e7; }

#side-menu li .nav-second-level li a {
  padding: 10px 10px 10px 30px;
  color: var(--color4, #6a6c6f);
  text-transform: none;
  font-weight: 600; }

#side-menu li .nav-second-level li.active a {
  background-color: #f7f9fa; }

#side-menu li .nav-third-level li a {
  padding: 10px 10px 10px 40px;
  color: var(--color4, #6a6c6f);
  text-transform: none;
  font-weight: 600; }

#side-menu.nav > li > a:hover,
#side-menu.nav > li > a:focus {
  background: #fff; }

#side-menu li.active {
  background: #fff; }

.arrow {
  float: right; }

.fa.arrow:before {
  content: "\f104"; }

.active > a > .fa.arrow:before {
  content: "\f107"; }

#top-navigation {
  height: 56px;
  position: relative;
  float: left; }

.navbar-static-top {
  margin: 0 0 0 180px; }

.navbar.navbar-static-top a,
.nav.navbar-nav li a {
  color: var(--color4-lighten20, #6a6c6f); }

.header-link {
  padding: 18px 26px 17px 26px;
  font-size: 14px;
  float: left;
  border-right: 1px solid #e4e5e7;
  border-left: 1px solid #e4e5e7;
  margin-right: 15px;
  background: #f7f9fa;
  cursor: pointer; }

.header-link:hover {
  background: #f1f4f6; }

.navbar-nav > li,
.navbar-nav > li > a {
  height: 56px; }

.navbar-nav > li > a {
  padding: 15px 20px;
  font-size: 25px; }

.navbar-nav > li:last-child > a {
  padding-right: 35px; }

.navbar.navbar-static-top .hide-menu {
  color: var(--color4-lighten20, #6a6c6f); }

.panel-title {
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase; }

/* Box */
.box {
  padding: 40px; }

.box-rb-border {
  border-right: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7; }

.box-b-border {
  border-bottom: 1px solid #e4e5e7; }

.box-r-border {
  border-right: 1px solid #e4e5e7; }

/* Sidebar Hide */
body.hide-sidebar #menu {
  margin-left: -180px; }

body.hide-sidebar #wrapper {
  margin-left: 0; }

body.page-small #menu {
  margin-left: -180px; }

body.page-small #wrapper {
  margin-left: 0; }

body.page-small.show-sidebar #menu {
  margin-left: 0; }

body.page-small.show-sidebar #wrapper {
  margin-left: 180px; }

a {
  cursor: pointer;
  color: #34495e; }

a:hover,
a:focus {
  color: #34495e;
  text-decoration: none; }

.no-padding {
  padding: 0 !important; }

.no-borders {
  border: none !important; }

.no-margins {
  margin: 0 !important; }

.no-background {
  background: transparent !important; }

.borders {
  border: 1px solid #e4e5e7 !important; }

.navbar-form-custom {
  float: left;
  height: 52px;
  padding: 0;
  width: 180px; }

.navbar-form-custom .form-control {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  font-size: 13px;
  height: 52px;
  margin: 0;
  z-index: 1060;
  padding: 0; }

.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
  box-shadow: none;
  border-color: #e4e5e7; }

.vertical-mid {
  display: table; }

.vertical-mid * {
  display: table-cell;
  vertical-align: middle; }

/* Labels */
.label-success {
  background-color: var(--color1, #62cb31); }

.well {
  background: #f7f9fa;
  box-shadow: none; }

/* Messages */
.feed-activity-list .feed-element {
  border-bottom: 1px solid #e7eaec; }

.feed-element:first-child {
  margin-top: 0; }

.feed-element {
  padding-bottom: 15px; }

.feed-element,
.feed-element .media {
  margin-top: 15px; }

.feed-element,
.media-body {
  overflow: hidden; }

.feed-element > .pull-left {
  margin-right: 10px; }

.feed-element img.img-circle,
.dropdown-messages-box img.img-circle {
  width: 38px;
  height: 38px; }

.feed-element .well {
  border: 1px solid #e7eaec;
  box-shadow: none;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px 20px;
  font-size: 11px;
  line-height: 16px; }

.feed-element .actions {
  margin-top: 10px; }

.feed-element .photos {
  margin: 10px 0; }

.feed-photo {
  max-height: 180px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px; }

.feed-element img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  /* Firefox 10+, Firefox on Android */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+, Safari 6+, Safari 6+ iOS */ }

.small-logo {
  display: none;
  text-align: center;
  padding-top: 18px;
  font-size: 14px;
  font-weight: 800; }

.img-small {
  width: 62px;
  height: 62px; }

@media (max-width: 768px) {
  .navbar-form-custom,
  .navbar-right,
  #logo {
    display: none; }
  .navbar-static-top {
    margin: 0; }
  .small-logo {
    display: block;
    float: left; }
  .header-line div {
    display: none; }
  .color-demo .header-line {
    height: 48px; }
  #hbreadcrumb {
    float: none !important;
    margin-bottom: 10px;
    margin-top: 0; } }

@media (max-width: 320px) {
  #wrapper {
    overflow: hidden; } }

.canvass {
  -moz-transition: width 0.01s, height 0.01s;
  -webkit-transition: width 0.01s, height 0.01s;
  -o-transition: width 0.01s, height 0.01s;
  transition: width 0.01s, height 0.01s; }

.project-list table tr td {
  border-top: none;
  border-bottom: 1px solid #e7eaec;
  padding: 10px 10px;
  vertical-align: middle; }

.icons-box {
  padding: 20px; }

.icons-box h3 {
  margin-top: 10px;
  margin-bottom: 10px; }

.icons-box .infont {
  text-align: center;
  margin-bottom: 25px; }

.icons-box .infont i {
  font-size: 35px;
  display: block;
  color: #676a6c; }

.icons-box .infont span {
  color: #a6a8a9; }

.icons-box .infont span {
  margin: 1px;
  display: block; }

.fullpanel {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1070;
  overflow-x: scroll;
  transition: all 2s; }

.transition {
  transition: all 0.5s; }

.normalheader .hpanel {
  margin-bottom: 0; }

.normalheader {
  min-width: 320px;
  padding: 40px 40px 0px 40px;
  transition: padding 0.5s; }

@media (max-width: 768px) {
  .normalheader {
    padding: 15px 15px 0px 15px; } }

.normalheader h2 {
  margin-top: 0px; }

.input-xs {
  height: 20px;
  padding: 2px 10px;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: 500; }

.clip-header i {
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.clip-header {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8px;
  background: #f7f9fa;
  padding: 4px 5px;
  border-left: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7; }

.dropdown-menu {
  font-size: 13px;
  border-radius: 3px; }

.dropdown-example {
  display: inline-block;
  margin: 0px 10px 10px 10px; }

.dropdown-example .dropdown-menu {
  position: static;
  display: block; }

.pagination-example .pagination {
  margin: 0 0 10px 0; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: var(--color2, #3498db);
  border-color: var(--color2-darken5, #3498db);
  color: #fff;
  cursor: default;
  z-index: 2; }

.hbreadcrumb a.active {
  color: #9d9fa2; }

.form-control {
  border-color: #e4e5e7; }

.input-group-addon {
  background: var(--color5-lighten3, #f1f3f6);
  border-color: #e4e5e7 !important; }

.sidebar-button {
  padding: 4px 10px 0 10px; }
  .sidebar-button i {
    font-size: 20px; }

/* Tables */
.table > thead > tr > th {
  border-bottom: none; }

.no-padding table {
  margin-bottom: 0; }

/* Validation */
.form-control.error {
  border-color: var(--color3, #e74c3c) !important; }

label.error,
label span.error {
  color: var(--color3, #e74c3c);
  margin: 5px 0 0 0;
  font-weight: 400; }

label span.error {
  margin-left: 5px; }

label.error:before {
  content: "\f071";
  font-family: "FontAwesome";
  margin: 0 4px; }

label.pdf:before {
  content: "\f1c1";
  font-family: "FontAwesome";
  margin: 0 4px; }

/* Big menu */
.hdropdown.bigmenu {
  padding: 30px 30px 0px 30px; }

.bigmenu a i {
  font-size: 46px; }

.bigmenu a {
  display: block;
  text-align: center;
  margin: 0 10px 30px 0;
  min-width: 100px; }

.bigmenu h5 {
  font-size: 13px;
  margin-top: 10px; }

/* Mobile navbar */
@media (max-width: 768px) {
  .mobile-menu-toggle {
    display: block; }
  .mobile-menu .navbar-nav > li,
  .mobile-menu .navbar-nav {
    float: none; } }

.mobile-navbar {
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 100; }
  .mobile-navbar .navbar-nav {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #e4e5e7; }
  .mobile-navbar .navbar-nav > li > a {
    padding: 15px 15px;
    font-size: 14px;
    color: var(--color4, #6a6c6f); }
  .mobile-navbar .navbar-nav > li > a:hover,
  .mobile-navbar .navbar-nav > li > a:focus,
  .mobile-navbar .navbar-nav .open > a,
  .mobile-navbar .navbar-nav .open > a:hover,
  .mobile-navbar .navbar-nav .open > a:focus {
    border-bottom: none; }

@media (min-width: 769px) {
  .mobile-navbar,
  .mobile-navbar.collapse.in {
    display: none; } }

/* Fix for xeditable and chekcbox plugin in angularJS version */
.x-edit-checkbox .checkbox label::before {
  display: none; }

.cursor-pointer {
  cursor: pointer; }

.hover-opacity {
  transition: 0.3s;
  opacity: 1; }
  .hover-opacity:hover {
    opacity: 0.7; }

.list-group-item-light {
  color: #818182;
  background-color: #e7e7ebd0 !important; }

.sc-AxhCb::before {
  height: 0.5em !important;
  width: 0.5em !important; }

/* Style The Dropdown Button */
.dropbtn {
  background-color: #4caf50;
  color: #bf0505;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

.dropbtn-menu {
  background-color: var(--color5, #f1f3f6);
  color: var(--color4, #6a6c6f);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer; }

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  margin: 0px 1px 0px 4px; }

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; }

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block; }

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1; }

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block; }

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41; }

.dropdown:hover .dropbtn-menu {
  background-color: var(--color1, #62cb31); }

.imageConfig {
  height: 230px;
  width: 400px; }

.bg-dark {
  background-color: var(--color5, #f1f3f6) !important; }

.class-font-text {
  color: var(--color4, #6a6c6f); }

.linha-vertical {
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: -35px 2px -35px 5px;
  height: 80px; }

body.light-skin {
  background-color: #F8F9FB; }
  body.light-skin .color-line {
    display: none; }
  body.light-skin.fixed-navbar #wrapper, body.light-skin #menu {
    top: 55px; }
  body.light-skin .welcome-message {
    display: none; }
  body.light-skin #wrapper {
    background-color: transparent;
    border-left: none; }
    body.light-skin #wrapper .content {
      padding-top: 20px; }
  body.light-skin.fixed-small-header #wrapper .content {
    padding-top: 90px; }
  body.light-skin #menu {
    width: 190px; }
  body.light-skin #side-menu {
    background: none;
    padding: 23px 0 50px 25px; }
    body.light-skin #side-menu li {
      border: none; }
      body.light-skin #side-menu li:first-child {
        border: none; }
      body.light-skin #side-menu li a {
        text-transform: none;
        font-weight: 600;
        padding: 10px 10px;
        color: #909295; }
        body.light-skin #side-menu li a:hover, body.light-skin #side-menu li a:focus {
          background-color: inherit;
          color: var(--color4, #6a6c6f);
          font-weight: 700; }
      body.light-skin #side-menu li.active {
        border: none;
        background: none; }
        body.light-skin #side-menu li.active > a {
          border: none;
          font-weight: 700;
          color: var(--color4, #6a6c6f); }
      body.light-skin #side-menu li .nav-second-level li a {
        padding: 6px 10px 6px 20px; }
      body.light-skin #side-menu li .nav-third-level li a {
        padding: 6px 10px 6px 30px; }
  body.light-skin .profile-picture {
    display: none; }
  body.light-skin .hpanel .panel-body {
    border-radius: 2px; }
  body.light-skin .panel-footer {
    border-radius: 0 0 2px 2px; }
  body.light-skin .hpanel .panel-body {
    border: none;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1); }
  body.light-skin .hpanel.panel-collapse .panel-heading {
    border: none;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1); }
  body.light-skin .hpanel .panel-heading {
    border: none; }
  body.light-skin .hpanel .hbuilt.panel-heading,
  body.light-skin .hpanel.blog-box .panel-heading {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1); }
  body.light-skin .hpanel.hgreen .panel-body {
    border-top: 2px solid var(--color1, #62cb31); }
  body.light-skin .hpanel.hblue .panel-body {
    border-top: 2px solid var(--color2, #3498db); }
  body.light-skin .hpanel.hyellow .panel-body {
    border-top: 2px solid #ffb606; }
  body.light-skin .hpanel.hviolet .panel-body {
    border-top: 2px solid #9b59b6; }
  body.light-skin .hpanel.horange .panel-body {
    border-top: 2px solid #e67e22; }
  body.light-skin .hpanel.hred .panel-body {
    border-top: 2px solid var(--color3, #e74c3c); }
  body.light-skin .hpanel.hreddeep .panel-body {
    border-top: 2px solid #c0392b; }
  body.light-skin .hpanel.hnavyblue .panel-body {
    border-top: 2px solid #34495e; }
  body.light-skin #header {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.1);
    height: 55px;
    border: none;
    z-index: 10; }
  body.light-skin .header-link {
    background-color: #ffffff; }
  body.light-skin #logo {
    height: 55px;
    width: 200px;
    padding-left: 33px; }
    body.light-skin #logo.light-version {
      border: none;
      background-color: #ffffff;
      text-align: left; }
      body.light-skin #logo.light-version span {
        font-weight: 700;
        color: #6a6c6f;
        font-size: 14px; }
  body.light-skin.fixed-small-header .small-header {
    top: 55px;
    z-index: 5; }
  body.light-skin .small-header {
    padding: 0  0 0 21px !important; }
  body.light-skin.hide-sidebar .small-header {
    padding: 0  0 0 0 !important; }
  body.light-skin .hpanel > .panel-footer, body.light-skin .hpanel > .panel-section {
    background-color: transparent;
    border: none; }
  body.light-skin .social-board .hpanel > .panel-footer, body.light-skin .social-board .hpanel > .panel-section {
    border: 1px solid #eaeaea; }
  body.light-skin .contact-panel.hpanel > .panel-footer, body.light-skin .contact-panel.hpanel > .panel-section {
    border: 1px solid #eaeaea; }
  body.light-skin .footer {
    left: -181px; }
  body.light-skin.fixed-footer .footer {
    left: 0; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }
  .animated.bounceIn {
    animation-duration: .7s; }
  .animated.bounceOut {
    animation-duration: .5s; }
  .animated.bounceIn {
    animation-name: bounceIn; }
  .animated.bounceOut {
    animation-name: bounceOut; }
  .animated.fadeIn {
    animation-name: fadeIn;
    animation-duration: .7s; }
  .animated.fadeOut {
    animation-name: fadeOut;
    animation-duration: .3s; }
  .animated.bounceInDown {
    animation-name: bounceInDown; }
  .animated.bounceOutUp {
    animation-name: bounceOutUp; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    -webkit-transform: none;
    transform: none; } }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.rrt-confirm-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999; }
  .rrt-confirm-holder .shadow {
    width: 100%;
    height: 100%;
    background-color: rgba(50, 58, 68, 0.8); }
  .rrt-confirm-holder .rrt-confirm {
    width: 320px;
    background-color: white;
    position: absolute;
    z-index: 9;
    top: 20%;
    left: 50%;
    margin-left: -160px;
    box-shadow: 3px 3px 20px #333;
    border-radius: 4px;
    overflow: hidden; }
    .rrt-confirm-holder .rrt-confirm .rrt-message {
      width: 100%;
      padding: 5%;
      min-height: 50px;
      font-size: 1em;
      background-color: white;
      text-align: center;
      font-family: 'open-sanscondensed-light', sans-serif;
      clear: both; }
    .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder {
      display: flex; }
      .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
        flex-grow: 1;
        height: 50px;
        text-transform: capitalize;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        float: left;
        border-top: 1px solid #f0f0f0;
        font-size: 14px;
        overflow: hidden;
        cursor: pointer; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:hover {
          background-color: #f5f5f5; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
          background-color: #fff;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
          background-color: #fff;
          color: white; }
        .rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button:focus {
          outline: none; }

body.toastr-confirm-active {
  overflow: hidden; }

.redux-toastr *, .redux-toastr *:before, .redux-toastr *:after {
  box-sizing: border-box; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  width: 350px;
  position: fixed;
  z-index: 99999999;
  padding: 0 10px; }

.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center {
  top: 0; }

.redux-toastr .top-right, .redux-toastr .bottom-right {
  right: 0; }

.redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  bottom: 0; }

.redux-toastr .top-left, .redux-toastr .bottom-left {
  left: 0; }

.redux-toastr .top-center, .redux-toastr .bottom-center {
  left: 50%;
  margin-left: -175px; }

@media (max-width: 320px) {
  .redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
    width: 320px; }
  .redux-toastr .top-center, .redux-toastr .bottom-center {
    margin-left: -160px; } }

.redux-toastr .toastr {
  background-color: #fcfcfc;
  width: 100%;
  min-height: 70px;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 4px;
  position: relative;
  z-index: 2;
  color: #333;
  opacity: 1;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2); }
  .redux-toastr .toastr:hover:not(.rrt-message) {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
    opacity: 1; }
  .redux-toastr .toastr .toastr-status {
    width: 100%;
    height: 5px; }
    .redux-toastr .toastr .toastr-status.success {
      background-color: #fff; }
    .redux-toastr .toastr .toastr-status.warning {
      background-color: #fff; }
    .redux-toastr .toastr .toastr-status.info {
      background-color: #fff; }
    .redux-toastr .toastr .toastr-status.error {
      background-color: #fff; }
  .redux-toastr .toastr .rrt-left-container, .redux-toastr .toastr .rrt-right-container {
    float: left;
    text-align: center;
    overflow: hidden; }
  .redux-toastr .toastr .rrt-left-container {
    width: 0px;
    top: 0;
    left: 0;
    position: absolute;
    bottom: 0;
    display: none; }
    .redux-toastr .toastr .rrt-left-container .rrt-holder {
      width: 0px;
      height: 0px;
      position: absolute;
      top: 50%;
      margin-top: -35px;
      left: 5px;
      line-height: 60px; }
    .redux-toastr .toastr .rrt-left-container .toastr-icon {
      fill: white;
      vertical-align: middle;
      margin-top: 5px; }
  .redux-toastr .toastr .rrt-middle-container {
    width: 65%;
    margin-left: 20px;
    position: relative;
    float: left;
    font-family: Arial, Helvetica, sans-serif, sans-serif;
    font-size: 1em;
    text-align: left;
    padding: 10px 5px; }
    .redux-toastr .toastr .rrt-middle-container .rrt-title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 5px; }
  .redux-toastr .toastr .rrt-right-container {
    width: 10%; }
  .redux-toastr .toastr .close-toastr {
    width: 10%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    font-size: 20px;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    font-family: "Helvetica Neue", Helvetica, Arial sans-serif;
    color: #000; }
    .redux-toastr .toastr .close-toastr:hover {
      opacity: 1; }
    .redux-toastr .toastr .close-toastr:focus {
      outline: none; }
    .redux-toastr .toastr .close-toastr span {
      position: absolute;
      left: 0;
      right: 0;
      top: 20%;
      transform: translateY(-50%); }
  .redux-toastr .toastr.rrt-info, .redux-toastr .toastr.rrt-success, .redux-toastr .toastr.rrt-warning, .redux-toastr .toastr.rrt-error {
    color: #6a6c6f; }
  .redux-toastr .toastr.rrt-info {
    background-color: #fff;
    border-left: 6px solid #3498db; }
    .redux-toastr .toastr.rrt-info .rrt-progressbar {
      background-color: #f2f2f2; }
  .redux-toastr .toastr.rrt-success {
    background-color: #fff;
    border-left: 6px solid #62cb31; }
    .redux-toastr .toastr.rrt-success .rrt-progressbar {
      background-color: #f2f2f2; }
  .redux-toastr .toastr.rrt-warning {
    background-color: #fff;
    border-left: 6px solid #ffb606; }
    .redux-toastr .toastr.rrt-warning .rrt-progressbar {
      background-color: #f2f2f2; }
  .redux-toastr .toastr.rrt-error {
    background-color: #fff;
    border-left: 6px solid #e74c3c; }
    .redux-toastr .toastr.rrt-error .rrt-progressbar {
      background-color: #f2f2f2; }
  .redux-toastr .toastr.rrt-light .rrt-progressbar {
    background-color: #ccc; }
  .redux-toastr .toastr.rrt-light .toastr-icon {
    fill: #333 !important; }
  .redux-toastr .toastr.rrt-message {
    opacity: 1;
    border: 1px solid #dbdbdb; }
    .redux-toastr .toastr.rrt-message .rrt-title {
      width: 90%;
      height: 50px;
      text-align: center;
      overflow: hidden;
      font-size: 1.2em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      line-height: 50px;
      padding: 0 20px; }
    .redux-toastr .toastr.rrt-message .rrt-text {
      width: 100%;
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
      border-top: 1px solid #f1f1f1;
      border-bottom: 1px solid #f1f1f1;
      background-color: white;
      padding: 15px;
      font-size: 1.1em;
      margin-bottom: 20px; }
      .redux-toastr .toastr.rrt-message .rrt-text img {
        display: block;
        margin: 10px auto;
        max-width: 100%; }
    .redux-toastr .toastr.rrt-message .close-toastr {
      height: 50px; }
  .redux-toastr .toastr .rrt-progress-container {
    height: 5px;
    margin: 0 -20px -20px -60px;
    position: absolute;
    bottom: 20px;
    width: 100%; }
    .redux-toastr .toastr .rrt-progress-container .rrt-progressbar {
      -webkit-border-radius: 0 0 0 4px;
      -moz-border-radius: 0 0 0 4px;
      border-radius: 0 0 0 4px;
      height: 100%; }

.redux-toastr .toastr-attention {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1; }
